<script setup>
import {ref, watchEffect} from 'vue';
import {usePage} from '@inertiajs/vue3';
import CloseIcon from '@/images/components/banner/close_icon.svg';
import DangerIcon from '@/images/components/banner/danger_icon.svg';
import SuccessIcon from '@/images/components/banner/success_icon.svg';
import WarningIcon from '@/images/components/banner/warning_icon.svg';

const page = usePage();

const props = defineProps({
    status: {
        type: String,
        default: null,
    },
    message: {
        type: String,
        default: null,
    },
});

const show = ref(true);
const style = ref(props.status || page.props?.jetstream?.flash?.bannerStyle);
const message = ref(props.message || page.props?.jetstream?.flash?.banner);

watchEffect(async () => {
    style.value = props.status || page.props?.jetstream?.flash?.bannerStyle || 'success';
    message.value = props.message || page.props?.jetstream?.flash?.banner || '';
    show.value = true;
});
</script>

<template>
    <div>
        <div
            v-if="show && message"
            :class="{
                'bg-success': style === 'success',
                'bg-error': style === 'danger',
                'bg-warning': style === 'warning',
            }">
            <div class="max-w-screen-xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
                <div class="flex items-center justify-between flex-wrap">
                    <div class="w-0 flex-1 flex items-center min-w-0">
                        <span
                            class="flex p-2 rounded-lg"
                            :class="{
                                'bg-success/60': style === 'success',
                                'bg-error/60': style === 'danger',
                                'bg-warning/60': style === 'warning',
                            }">
                            <SuccessIcon v-if="style === 'success'" class="h-5 w-5 text-success-content" />
                            <DangerIcon v-if="style === 'danger'" class="h-5 w-5 text-danger-content" />
                            <WarningIcon v-if="style === 'warning'" class="h-5 w-5 text-warning-content" />
                        </span>

                        <p class="ms-3 font-medium text-sm text-success-content truncate">
                            {{ $t(message) }}
                        </p>
                    </div>

                    <div class="shrink-0 sm:ms-3">
                        <button
                            type="button"
                            class="-me-1 flex p-2 rounded-md focus:outline-none sm:-me-2 transition"
                            :class="{
                                'hover:bg-success/60 focus:bg-success/60': style === 'success',
                                'hover:bg-error/60 focus:bg-error/60': style === 'danger',
                            }"
                            aria-label="Dismiss"
                            @click.prevent="show = false">
                            <CloseIcon
                                class="h-5 w-5"
                                :class="{
                                    'text-success-content': style === 'success',
                                    'text-error-content': style === 'danger',
                                    'text-warning-content': style === 'warning',
                                }" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
